import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox,
  OwnUpHeader
} from '@rategravity/own-up-component-library';
import React, { PropsWithChildren } from 'react';

const FieldStyle = createOwnUpStyle({
  marginLeft: 'min(calc((1280px - 100vw) / 2), 0px)',
  marginRight: 'min(calc((1280px - 100vw) / 2), 0px)',
  width: '100vw'
});

const Field = createOwnUpComponent(OwnUpBox, FieldStyle);

const FullWidthFieldHeaderStyle = createOwnUpStyle({
  padding: '145px 32px 145px',
  fontSize: 48,
  lineHeight: 1.3,
  textAlign: 'center',
  letterSpacing: 0,
  fontWeight: 400,
  maxWidth: 953,
  marginBottom: 0,
  variants: {
    medium: {
      padding: 60,
      fontSize: 36,
      width: 'unset'
    },
    small: {
      padding: 60,
      fontSize: 30
    },
    xsmall: {
      fontSize: 28,
      padding: 20
    }
  }
});

export const FullWidthFieldHeader = createOwnUpComponent(OwnUpHeader, FullWidthFieldHeaderStyle);

export const FullWidthField = ({
  backgroundColor,
  children
}: PropsWithChildren<{ backgroundColor: string }>) => (
  <Field style={{ backgroundColor }}>{children}</Field>
);
