import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox,
  ScreenSize,
  useScreenSize
} from '@rategravity/own-up-component-library';
import React, { PropsWithChildren } from 'react';
import { FullWidthField } from '../full-width-field';

const ContentWrapperStyle = createOwnUpStyle({
  maxWidth: 1280,
  boxSizing: 'border-box',
  padding: '32px 60px',
  display: 'flex',
  alignItems: 'center',
  margin: 'auto',
  '& img': {
    borderRadius: '50%'
  },
  variants: {
    smallAndDown: {
      flexDirection: 'column',
      padding: 40
    }
  }
});

const ContentWrapper = createOwnUpComponent(OwnUpBox, ContentWrapperStyle);

const ContentStyle = createOwnUpStyle({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
});

const Content = createOwnUpComponent(OwnUpBox, ContentStyle);

const ImageWrapperStyle = createOwnUpStyle({
  width: '100%',
  margin: 'auto',
  textAlign: 'center'
});

const ImageWrapper = createOwnUpComponent(OwnUpBox, ImageWrapperStyle);

export interface PhotoFeatureProps {
  backgroundColor: string;
  label: string;
  text: JSX.Element;
  imagePosition?: 'left' | 'right';
  photoSpacing?: number;
}
const PhotoFeatureContentPane = ({
  text,
  photo,
  photoSpacing,
  panePosition,
  imagePosition
}: {
  text: JSX.Element;
  photo: JSX.Element;
  photoSpacing: number;
  panePosition: PhotoFeatureProps['imagePosition'];
  imagePosition: PhotoFeatureProps['imagePosition'];
}) => {
  const screenSize = useScreenSize();
  let content;
  let style = {};
  if (screenSize <= ScreenSize.small) {
    content = panePosition === 'left' ? text : photo;
    style = {
      width: '100%',
      padding: '30px 0'
    };
  } else if (imagePosition === panePosition) {
    content = photo;
    style = {
      width: `${photoSpacing}%`,
      padding: '50px 90px 50px 0'
    };
  } else {
    content = text;
    style = { width: `${100 - photoSpacing}%` };
  }

  return <Content style={style}>{content}</Content>;
};

// eslint-disable-next-line abcsize/abcsize
export const PhotoFeature = ({
  backgroundColor,
  children,
  label,
  text,
  imagePosition = 'right',
  photoSpacing = 50 // default to photo and text each taking up 50% of width
}: PropsWithChildren<PhotoFeatureProps>) => {
  const photo = <ImageWrapper>{children}</ImageWrapper>;

  return (
    <section aria-label={`featured-quote-${label}`}>
      <FullWidthField backgroundColor={backgroundColor}>
        <ContentWrapper>
          <PhotoFeatureContentPane
            {...{ text, photo, photoSpacing, panePosition: 'left', imagePosition }}
          />
          <PhotoFeatureContentPane
            {...{ text, photo, photoSpacing, panePosition: 'right', imagePosition }}
          />
        </ContentWrapper>
      </FullWidthField>
    </section>
  );
};
