import { getImage, ImageQuery } from '@rategravity/marketing-components';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  fonts,
  OwnUpBox,
  OwnUpText
} from '@rategravity/own-up-component-library';
import React, { PropsWithChildren } from 'react';
import { metadata } from '../../../data/metadata/images';
import { colors } from '../../../modules/colors';
import { PhotoFeature } from '../../photo-feature';
import { SectionHeader } from '../../typography';

const DividerStyle = createOwnUpStyle({
  backgroundColor: colors.PRIMARY,
  height: 2,
  margin: '30px 0',
  width: 50
});

const Divider = createOwnUpComponent(OwnUpBox, DividerStyle);

const GreyFieldTextStyle = createOwnUpStyle({
  ...fonts.GRAPHIK_REGULAR,
  fontFamily: "'Graphik-Regular', 'Arial', 'sans-serif'",
  fontSize: 29,
  variants: {
    xsmall: {
      fontSize: 18
    }
  }
});

const GreyFieldText = createOwnUpComponent(OwnUpText, GreyFieldTextStyle);

export const imgStyle = {
  maxWidth: 508,
  margin: 'auto',
  position: 'relative' as const,
  borderRadius: '50%'
};

export const AndrewImage = ({ imageData }: { imageData: ImageQuery }) =>
  getImage(imageData, 'andrew.jpg', metadata, imgStyle);

export const GreyFeatureSection = ({ children }: PropsWithChildren<{}>) => {
  const text = (
    <>
      <SectionHeader>
        <div style={{ textIndent: -24 }}>
          “Patrick was there every step of the way and made sure it was smooth sailing.”
        </div>
      </SectionHeader>
      <Divider />
      <GreyFieldText>Andrew, Boston MA</GreyFieldText>
    </>
  );
  return (
    <PhotoFeature backgroundColor={colors.LIGHT_GREY} label="andrew" text={text}>
      {children}
    </PhotoFeature>
  );
};
